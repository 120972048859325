@import "../../../styles/base/abstracts";

.hbs-breadcrumbs {
  @include site-max-width;
  position: relative;
  overflow: visible;
}

.hbs-breadcrumbs__content {
  @include grid-parent;
  @include padding($spacing-sm + 1 x $spacing-sm);
  flex-flow: nowrap;
  overflow-x: auto;
  position: relative;

  @include mq($bp-desktop-lg) {
    overflow: hidden;
  }
}

.hbs-breadcrumbs__list {
  @include grid-child;
  display: flex;
  width: auto;
}

@each $theme in $bg-themes {
  [data-theme="#{$theme}"] + .hbs-breadcrumbs__wrapper[data-theme="#{$theme}"] {
    border-top: $border-light;
  }
}
