@import "../../../styles/base/abstracts";

.hbs-breadcrumbs-item {
  @include small-text($font-weight: $fw-semibold);
  align-items: center;
  display: flex;
  flex-shrink: 0;
  margin-bottom: 0;
  position: relative;

  &:last-child {
    .hbs-breadcrumbs-item__link {
      margin-right: $grid-padding-mobile * 2;
      pointer-events: none;
      color: $c-text;
    }
  }

  .hbs-breadcrumbs-item__link {
    @include subtle-link($c-text-light, $c-spot);
  }
}

.hbs-breadcrumbs-item {
  &:last-child {
    .hbs-icon {
      display: none;
    }
  }

  .hbs-icon {
    @include margin(x $spacing-sm);
    @include size($caret-size-lg);
    fill: none;
    stroke: $c-text-light;
    transform: rotate(-90deg);
  }
}
