@mixin hover {
  @media not all and (hover: none) {
    &:hover {
      @content;
      outline: none;
    }
  }

  &:focus {
    &:not(:hover) {
      outline: $outline;
      outline-offset: 1px;
    }

    &:not(:focus-visible) {
      outline: none;
    }
  }
}
